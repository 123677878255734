.breadcrumb {

    ul  {
        display: flex;
        flex-wrap: wrap;
        min-height: 60px;
    }

    li {
        display: flex;
        align-items: center;
        color: $color-white;

        &:after {
            content: "";
            display: inline-block;
            width: 26px;
            height: 26px;
            background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/home/ic-nav-chevron-right-white.svg) no-repeat 50%;
            background-size: contain;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        a {
            color: $color-white;
            display: flex;
            align-items: center;

            &:hover {
                color: $color-white;
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

}
