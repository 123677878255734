.sms-alerts {

    margin: 30px 0;
    padding-bottom: 20px;

    &__field {
        margin: 0 0 30px 0;

        input {
            max-width: 170px;
            margin-top: 5px;
        }

        &--list {
            label {
                font-weight: $font-weight;
            }

            input {
                margin-right: 8px;
            }
        }
    }

    &__submit {
        margin: 30px 0;
    }

    &__unsubscribe {
        color: $color-text;
        text-decoration: underline;

        &:hover, &:focus {
            text-decoration: none;
        }
    }

    &__infos {
        margin: 5px 0 15px;
        padding: 10px 20px;
        border-left: 6px solid $color-dark;
        font-size: $font-size--text;
        font-weight: $font-weight-bold;
        color: $color-text;

        &--error {
            background-color: $color-bg-error;
            border-color: $color-error;
            color: $color-error;
        }
    
        &--alert {
            background-color: $color-bg-alert;
            border-color: $color-alert;
            color: $color-alert;
        }
    
        &--success {
            background-color: $color-bg-success;
            border-color: $color-success;
            color: $color-success;
        }

        &--nomargin {
            margin-bottom: 0;
        }
    }
}
