.footer {
    position: relative;
    padding-top: 55px;
    z-index: 3;
    
    &:before {
        content: "";
        position: absolute;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        z-index: 1;
        left: 0;
        border-width: 55px 0 0 100vw;
        border-color: transparent transparent transparent $color-white;
    }
    
    &:after {
        content: "";
        position: absolute;
        width: 100vw;
        height: 75px;
        top: -20px;
        left: 0;
        z-index: 0;
        background-color: $color-main;
    }

    &__top {
        background-color: $color-white;
    }

    &__infos {
        display: flex;
        padding: 30px 0 45px;

        &__logo {
            position: relative;
            margin-right: 130px;

            img {
                position: relative;
                z-index: 2;
            }

            &:before {
                content: '';
                position: absolute;
                top: -92px;
                left: -45px;
                width: 450px;
                height: 375px;
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/forme-logofooter.svg) no-repeat center;
                background-size: contain;
                filter: drop-shadow(10px 0px 50px rgba(0, 0, 0, 0.08));
                z-index: 1;
            }
        }

        &__address {
            margin-right: 85px;
        }

        &__hours {
            max-width: 270px;
        }

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--4;
            margin: 25px 0 15px;
        }

        &__phone {
            display: flex;
        }

        &__contact {
            margin: 20px 0 0;
            width: 230px;
            height: 60px;
            padding: 0;

            svg {
                margin-right: 8px;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 30px;

        &__item {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 15px;
                background: $color-second;
                transform: translateY(-50%);
            }

            &:first-child {
                .footer {
                    &__menu {
                        &__link {
                            padding: 0 15px 0 0;
                        }
                    }
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }

                .footer {
                    &__menu {
                        &__link {
                            padding: 0 0 0 15px;
                        }
                    }
                }
            }
        }

        &__link {
            font-size: 0.75rem;
            line-height: 1.25rem;
            text-transform: uppercase;
            padding: 0 15px;

            &:hover {
                color: $color-text;
                text-decoration: underline;
            }
        }
    }
}

body {
    &.home {
        .footer {
            margin-top: -55px;
    
            &:after {
                display: none;
            }
        }
    }
    &.error404 {
        .footer {    
            &:after {
                display: none;
            }
        }
    }
}

// 1200
@media screen and (max-width: $large) {
    .footer {
        &__infos {
            padding: 20px 0 45px;

            &__logo {
                margin-right: 100px;

                &:before {
                    width: 355px;
                    height: 295px;
                    top: -75px;
                    left: -40px;
                }

                img {
                    width: 280px;
                    height: 155px;
                }
            }

            &__address {
                margin-right: 70px;
                width: 250px;
            }
        }

        &__menu {
            &__item {
                &:first-child {
                    .footer__menu__link {
                        padding: 0 25px 0 0;
                    }
                }

                &:last-child {
                    .footer__menu__link {
                        padding: 0 0 0 25px;
                    }
                }
            }

            &__link {
                padding: 0 25px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &__infos {
            flex-wrap: wrap;

            &__logo {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-right: 0;
                margin-bottom: 85px;

                &:before {
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }

        &__menu {
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 40px 10px;

            &__item {
                margin-bottom: 15px;

                &:first-child {
                    .footer__menu__link {
                        padding: 0 25px;
                    }
                }

                &:last-child {
                    .footer__menu__link {
                        padding: 0 25px;
                    }
                }

                &:nth-of-type(3) {
                    &:after {
                        display: none;
                    }
                }
            }

            &__link {
                padding: 0 25px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__infos {
            text-align: center;

            &__logo {
                margin-bottom: 65px;

                &:before {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            &__title {
                margin: 40px 0 15px;
            }

            &__address {
                margin-right: 0;
                width: 100%;
            }

            &__phone {
                justify-content: center;
            }

            &__hours {
                max-width: 100%;
                width: 100%;
            }
        }

        &__menu {
            padding: 0 0 10px;

            &__item {
                &:nth-of-type(4),
                &:nth-of-type(6) {
                    .footer__menu__link {
                        padding: 0 0 0 40px;
                    }
                }
                &:nth-of-type(3),
                &:nth-of-type(5) {
                    .footer__menu__link {
                        padding: 0 40px 0 0;
                    }
                }
                &:nth-of-type(2) {
                    .footer__menu__link {
                        padding: 0;
                    }
                }

                &:nth-of-type(3) {
                    &:after {
                        display: block;
                    }
                }

                &:nth-of-type(1),
                &:nth-of-type(2),
                &:nth-of-type(4) {
                    &:after {
                        display: none;
                    }
                }
            }

            &__link {
                padding: 0 40px;
            }
        }
    }
}