.wrapper {

    #comarquage {


        // Démarches PARTICULIERS
        #icon-papiers {
            .color-11 {
                fill: $co-color-light;
            }
            .color-22 {
                fill: $co-color-third;
            }
            .color-27 {
                fill: $co-color-fourth;
            }
        }
        #icon-famille {
            .color-11 {
                fill: $co-color-fourth;
            }
            .color-27 {
                fill: $co-color-third;
            }
            path {
                &:nth-child(6), &:nth-child(8) {
                    fill: $co-color-light;
                }
            }
        }
        #icon-sante {
            .color-11 {
                fill: $co-color-light;
            }
            .color-22 {
                fill: $co-color-light;
            }
            .color-27 {
                fill: $co-color-fourth;
            }
            path {
                &:nth-child(8), &:nth-child(9), &:nth-child(10), &:nth-child(11), &:nth-child(12), &:nth-child(13) {
                    fill: $co-color-third;
                }
            }
        }
        #icon-travail {
            .color-1, .color-27 {
                fill: $co-color-fourth;
            }
            .color-11, .color-20, .color-32 {
                fill: $co-color-third;
            }
            path:nth-child(2), .color-5 {
                fill: $co-color-light;
            }
        }
        #icon-logement {
            .color-11 {
                fill: $co-color-fourth;
            }
            .color-22, .color-13 {
                fill: $co-color-light;
            }
            .color-27, .color-1, .color-14 {
                fill: $co-color-third;
            }
            path {
                &:nth-child(5), &:nth-child(8) {
                    fill: $co-color-fourth;
                }
            }
        }
        #icon-transports {
            .color-1, .color-31 {
                fill: $co-color-fourth;
            }
            .color-2 {
                fill: $co-color-light;
            }
            .color-27, .color-29, .color-30 {
                fill: $co-color-third;
            }
        }
        #icon-argent {
            .color-11 {
                fill: $co-color-fourth;
            }
            .color-22, path:nth-child(2) {
                fill: $co-color-light;
            }
            .color-27 {
                fill: $co-color-third;
            }
        }
        #icon-justice {
            .color-27 {
                fill: $co-color-fourth;
            }
            .color-2, .color-11, path:nth-child(14) {
                fill: $co-color-light;
            }
            .color-1, .color-28 {
                fill: $co-color-third;
            }
        }
        #icon-etrangers {
            .color-11 {
                fill: $co-color-light;
            }
            .color-1, .color-28 {
                fill: $co-color-fourth;
            }
            .color-27, .color-2 {
                fill: $co-color-third;
            }
        }
        #icon-loisirs {
            .color-1 {
                fill: $co-color-fourth;
            }
            path:nth-child(2) {
                fill: $co-color-light;
            }
            .color-11, .color-27 {
                fill: $co-color-third;
            }
        }


        // Démarches PROFESSIONNELS
        #icon-creation {
            .color-12 {
                fill: $co-color-fourth;
            }
            .color-10, .color-13, .color-15 {
                fill: $co-color-light;
            }
            .color-1, .color-14, .color-16, .color-17, path:nth-child(6) {
                fill: $co-color-third;
            }
        }
        #icon-gestion {
            .color-11 {
                fill: $co-color-light;
            }
            path:nth-child(2), path:nth-child(3), path:nth-child(4), path:nth-child(5) {
                fill: $co-color-fourth;
            }
            .color-10 {
                fill: $co-color-third;
            }
        }
        #icon-fiscalite {
            .color-18, .color-10 {
                fill: $co-color-fourth;
            }
            .color-11 {
                fill: $co-color-light;
            }
            .color-14, .color-19, .color-20, .color-21 {
                fill: $co-color-third;
            }
            .color-12 {
                fill: $color-bg--neutral;
            }
        }
        #icon-ressources-humaines {
            .color-5 {
                fill: $co-color-fourth;
            }
            path:nth-child(4) {
                fill: $co-color-light;
            }
            .color-10 {
                fill: $co-color-third;
            }
        }
        #icon-commerce {
            .color-7, .color-9 {
                fill: $co-color-fourth;
            }
            .color-11, .color-6 {
                fill: $co-color-light;
            }
            .color-10 {
                fill: $co-color-third;
            }
        }
        #icon-secteurs2 {
            .color-7, .color-9, .color-6, .color-22, .color-23 {
                fill: $co-color-fourth;
            }
            path:nth-child(2), .color-6, .color-10 {
                fill: $co-color-light;
            }
            .color-5, .color-10, .color-24 {
                fill: $co-color-third;
            }
        }


        // Démarches ASSOCIATIONS
        #icon-obligations {
            .color-5 {
                fill: $co-color-fourth;
            }
            .color-6 {
                fill: $co-color-light;
            }
            .color-3 {
                fill: $co-color-third;
            }
        }
        #icon-fonctionnement {
            path:nth-child(4) {
                fill: $co-color-light;
            }
            .color-4 {
                fill: $co-color-fourth;
            }
            .color-3 {
                fill: $co-color-third;
            }
        }
        #icon-financement {
            .color-6 {
                fill: $co-color-light;
            }
            .color-5 {
                fill: $co-color-fourth;
            }
            .color-3 {
                fill: $co-color-third;
            }
        }
        #icon-secteurs {
            .color-5 {
                fill: $co-color-light;
            }
            .color-4, .color-8 {
                fill: $co-color-fourth;
            }
            .color-3 {
                fill: $co-color-third;
            }
        }

    }

}
