.header {
    background: $color-white;

    &__content {
        display: flex;
        position: relative;
        height: 115px;
    }

    .identity {
        position: absolute;
        top: 145px;
        z-index: 1;
        display: flex;

        &__title {
            font-size: 0;
            line-height: 0;
        }

        &__logo {
            &--basic {
                display: flex;
            }
            &--fixed {
                display: none;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}


.tools {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .tool {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        padding: 0;
        border: none;
        height: 40px;
        text-transform: uppercase;
        font-size: 0.75rem;
        font-family: $font-family--heading;
        font-weight: $font-weight;

        svg {
            fill: $color-dark;
        }

        &:hover {
            svg {
                fill: $color-white;
            }
        }

        &--search,
        &--sms {
            margin: 0 20px 0 0;
            width: 165px;

            svg {
                width: 26px;
                height: 26px;
            }

            &:hover {
                color: $color-white;
                background-color: $color-fourth;
            }
        }

        &--search {
            background-color: $color-second;
        }

        &--sms {
            background-color: $color-third;
        }

        &--accessibility,
        &--fb {
            background-color: $color-main;
            margin: 0 0 0 10px;
            width: 40px;

            svg {
                height: 40px;
                width: 40px;
            }

            &:hover {
                background-color: $color-fourth;
            }
        }
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}
@keyframes headerFixed {
    from {
        top: -200px;
    }
    to {
        top: 0;
    }
}
@keyframes headerFixedAdmin {
    from {
        top: -200px;
    }
    to {
        top: 32px;
    }
}

.header--fixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 11;
    box-shadow: $shadow;
    animation: 1s headerFixed;

    .identity {
        top: 15px;
        z-index: 10;

        &__logo {
            &--basic {
                display: none;
            }
            &--fixed {
                display: flex;
            }
        }
    }

    .header {
        &__content {
            height: 80px;
        }
    }

    .tools {
        top: 0;
        bottom: 0;
        margin: auto;

        .tool {
            &--search,
            &--sms {
                width: 130px;
            }

            &--accessibility {
                margin: 0;
            }
        }
    }
}

.admin-bar {
    .header--fixed {
        top: 32px;
        animation: 1s headerFixedAdmin;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    .header {
        .identity {
            &__logo {
                &--basic {
                    width: 298px;
                }
            }
        }
    }

    .tools {
        .tool {
            &--sms {
                width: 40px;

                span {
                    display: none;
                }
            }

            &--search {
                width: 140px;
            }

            &--accessibility {
                margin: 0;
            }
        }
    }

    .header--fixed {
        .tools {
            .tool {
                &--sms,
                &--search {
                    width: 40px;
                    margin: 0 10px 0 0;
                }
                &--search {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        &__content {
            height: 140px;
            padding-bottom: 40px;
            align-items: flex-end;
        }

        .identity {
            position: relative;
            top: auto;

            &__logo {
                &--basic {
                    width: 265px;
                }
            }
        }
    }

    .tools {
        position: relative;
        top: auto;

        .tool {
            &--sms,
            &--search {
                width: 40px;
                margin: 0 10px 0 0;
            }
            &--search {
                span {
                    display: none;
                }
            }
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .tools-view {
        .container--tools-view {
            .profils {
                margin-top: 80px;
                margin-right: 35px;
            }
        }
    }

    .header {
        &__content {
            justify-content: space-between;
            height: auto;
            flex-wrap: wrap;
            padding: 30px 0 40px;
        }

        .identity {
            width: 100%;
            order: 3;
            margin-top: 40px;
        }
    }

    .tools {
        order: 2;

        .tool {
            margin: 0 0 0 10px;
        }
    }

}
