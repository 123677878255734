.cover {
    position: relative;
    padding-bottom: 55px;
    overflow: hidden;

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        z-index: 1;
        left: 0;
        border-color: transparent transparent $color-fourth;
        border-style: solid;
        border-width: 0 0 55px 100vw;
        z-index: -1; 
    }

    &:before {
        top: 126px;
    }

    &:after {
        transform: rotate(180deg);
        bottom: 1px;
    }

    > .container {
        position: relative;
        display: flex;
        flex-direction: row-reverse;

        &:before {
            content: '';
            position: absolute;
            left: -1000px;
            top: 180px;
            width: calc(100% + 2000px);
            height: calc(100% - 180px);
            background-color: $color-fourth;
            z-index: -1;
        }

        &:after {
            content: "";
            position: absolute;
            left: -280px;
            bottom: -200px;
            width: 300px;
            height: 300px;
            background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/cercles-top-publication.svg) no-repeat 50%;
            background-size: contain;
            opacity: 1;
        }
    }

    &__image-wrapper {
        position: relative;
        width: 700px;
        height: 545px;
        flex: none;
        margin-bottom: 25px;
        
        &:after {
            content: "";
            position: absolute;
            right: -110px;
            top: 0;
            width: 300px;
            height: 300px;
            background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/alu/cercles-alu.svg) no-repeat 50%;
            background-size: contain;
            opacity: .5;
        }

        svg {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
        }
    }

    &__informations {
        width: 470px;
        flex: none;
        padding: 240px 0 20px;
    }

    &__title {
        color: $color-white;
        margin: 25px 0 10px;
    }

    &__intro {
        color: $color-white;
        font-size: $font-size--5;
        line-height: 1.5rem;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__image-wrapper {
            width: 480px;
            height: 455px;
        }

        &__informations {
            width: 460px;
            padding: 210px 0 20px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .cover {
        > .container {
            flex-direction: column;
        }

        &__image-wrapper {
            width: 100%;
            height: auto;
        }

        &__informations {
            padding: 0 0 20px;
            width: 100%;
        }
    }

}
