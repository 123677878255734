.home {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 45px;

        &__left {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__subtitle {
        font-family: $font-family--home;
        font-size: 3.125rem;
        line-height: 1;
        opacity: .8;
        transform: rotate(-3deg);
    }

    &__title {
        position: relative;
        font-size: 3.125rem;
        border-bottom: none;
        padding-bottom: 0;
        margin: -10px 0 0;
        text-transform: uppercase;
        font-weight: $font-weight-light;
        letter-spacing: 0.1em;

        span {
            position: relative;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 7px;
            height: 10px;
            width: 100%;
            opacity: .5;
            border-radius: $border-radius;
            z-index: 0;
        }
    }

    &__btn {
        width: 250px;
        height: 60px;
        padding: 0;

        svg {
            margin-right: 8px;
        }
    }

    &__navigation {
        display: flex;
    }

    .swiper-button {
        width: 50px;
        height: 50px;
        margin: 0 0 0 20px;
        padding: 0;
        border-radius: $border-radius--round;
        background-color: $color-third;
        border: none;
        position: relative;

        &:hover {
            background-color: $color-main;
        }

        &-prev {
            left: 0;
        }

        &-next {
            right: 0;
        }

        &:after {
            display: none;
        }

        svg {
            fill: $color-dark;
            width: 50px;
            height: 50px;

            path {
                fill: $color-dark;
            }
        }
    }

    //======================================================================================================
    // Accès rapides
    //======================================================================================================
    .access {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-bottom: 10px;

        &__list {
            display: flex;
            flex-wrap: wrap;
        }

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            width: 140px;
            height: 145px;
            background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/forme-accesrapides.svg) no-repeat center;            
            background-size: contain;
            margin: 10px 15px 10px 0;
            color: $color-text;

            &:hover {
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/forme-accesrapides-hover.svg) no-repeat center;            
                background-size: contain;
                color: $color-text;

                .access {
                    &__svg-container {
                        svg {
                            path {
                                &[fill="#72D8A7"]{
                                    fill: $color-third;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__label {
            font-family: $font-family--heading;
            font-size: $font-size--text-small;
            line-height: 1.063rem;
            text-align: center;
            margin: 5px 0;
        }

        &__svg-container {
            display: flex;
            justify-content: center;

            svg {
                path {
                    transition: $duration;
                }
            }
        }
    }

    //======================================================================================================
    // SlideShow
    //======================================================================================================
    .access-alu {
        > .container {
            display: flex;
            justify-content: space-between;
        }
    }

    .alu {
        position: relative;
        z-index: 2;
        padding-bottom: 65px;

        &:before {
            content: "";
            position: absolute;
            right: -110px;
            top: 0;
            width: 300px;
            height: 300px;
            background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/alu/cercles-alu.svg) no-repeat center;
            background-size: contain;
            opacity: 1;
        }

        .swiper-container {
            padding: 60px;
            margin: -60px;
        }

        .slideshow {
            height: 660px;
            width: 800px;

            img, video {
                position: absolute;
                object-fit: cover;
                object-position: top;
                width: 100%;
                height: 620px;
            }

            &__container {
                display: inherit;
                position: relative;
                width: 100%;
                height: 620px;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                bottom: -40px;
                width: 380px;
                height: 240px;
                padding: 10px 50px 30px;
                color: $color-text;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/alu/forme-legendealu.svg) no-repeat center;
                    background-size: contain;
                    filter: drop-shadow(10px 0px 50px rgba(0, 0, 0, 0.08));
                }
            }

            &__title {
                margin: 0 0 10px;
                font-size: $font-size--4;
                font-weight: $font-weight-bold;
                line-height: 1.5rem;
                position: relative;
            }

            &__description {
                position: relative;
                line-height: 1.375rem;
            }

            &--video {
                .shape-container--img {
                    display: none;
                }
            }

            // Navigation (arrow)
            &__navigation {
                position: absolute;
                right: 0;
                left: auto;
                bottom: 80px;
            }
        
        }
    }

    //======================================================================================================
    // Actualités
    //======================================================================================================
    .posts {
        position: relative;
        background-color: $color-fourth;
        padding: 120px 0 155px;

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            z-index: 1;
            left: 0;
        }
        
        &:before {
            bottom: 0;
            border-width: 55px 0 0 100vw;
            border-color: transparent transparent transparent $color-white;
        }
        
        &:after {
            top: 0;
            border-width: 55px 100vw 0 0;
            border-color: $color-white transparent transparent transparent;
        }

        > .container {
            position: relative;

            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 500px;
                height: 500px;
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/cercles-actu.svg) no-repeat center;
            }

            &:before {
                right: -250px;
                top: -360px;
            }

            &:after {
                left: -350px;
                bottom: -350px;
            }
        }

        &__subtitle {
            color: $color-main;
        }

        &__title {
            color: $color-white;

            &:before {
                background-color: $color-main;
            }
        }

        &__list {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
        }

        &__item {
            position: relative;
            width: 370px;

            &:hover {
                .posts {
                    &__item {
                        &__container-img {
                            &:before {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &__taxo {
                            background-color: $color-white;
                            color: $color-text;
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                width: 100%;
                height: 240px;
                overflow: hidden;
                border-radius: $border-radius;
                background: url(/wp-content/themes/noyau/assets/images/icon-post.png) no-repeat center $color-gray;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 0;
                    height: 0;
                    background-color: $color-fourth;
                    border-radius: $border-radius;
                    opacity: .5;
                    transition: .35s;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__title {
                color: $color-white;
                margin: 15px 0 10px;
                line-height: 2rem;
            }

            &__intro {
                color: $color-white;
            }

            &__taxo {
                display: flex;
                position: absolute;
                top: 10px;
                left: 10px;
                background-color: $color-main;
                padding: 10px;
                border-radius: 50px;
                font-size: $font-size--text-small;
                font-weight: $font-weight-semibold;
                z-index: 1;
            }
        }

        &__btn-all {
            position: relative;
            margin: 0;
            z-index: 2;
        }
    }

    //======================================================================================================
    // Fil d'infos
    //======================================================================================================
    .newsthread {
        position: relative;
        z-index: 2;
        margin: -80px 0 0;

        &__container {
            position: relative;
            display: flex;
            align-items: center;
            border-radius: 90px;
            height: 100px;
            background-color: $color-white;
            box-shadow: 10px 0 50px rgb(0 0 0 / 8%);
            padding: 0 170px 0 0;
            overflow: hidden;
        }

        &__slider {
            //flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            flex: auto;
        }

        &__title {
            position: relative;
            display: flex;
            align-items: center;
            flex: none;
            padding-right: 90px;

            &__text {
                position: relative;
                font-family: $font-family--heading;
                font-size: $font-size--2;
                font-weight: $font-weight--heading;
            }

            svg {
                position: relative;
                margin: 0 30px;
            }

            &:before {
                content: '';
                position: absolute;
                width: 405px;
                height: 235px;
                top: -75px;
                left: -40px;
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/forme-filinfo.svg) no-repeat 50%;
                background-size: contain;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            min-height: 50px;
            color: $color-text;
            font-size: $font-size--5;
            line-height: 1;

            &:hover {
                text-decoration: underline;

                .newsthread {
                    &__link {
                        text-decoration: underline;
                    }
                }
            }
        }

        &__nav {
            position: absolute;
            right: 30px;
        }
    }

    //======================================================================================================
    // Agenda
    //======================================================================================================
    .events {
        padding: 75px 0 120px;

        > .container {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                right: -210px;
                bottom: -260px;
                width: 500px;
                height: 500px;
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/cercles-event.svg) no-repeat 50%;
                background-size: contain;
                opacity: .5;
            }
        }

        &__subtitle {
            color: $color-second;
        }

        &__title {
            color: $color-text;

            &:before {
                background-color: $color-second;
            }
        }

        &__list {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
        }

        &__item {
            position: relative;
            width: 570px;

            &:hover {
                .events {
                    &__item {
                        &__date {
                            color: $color-text;

                            &:before {
                                transform: scale(1.1);
                            }
                        }

                        &__hour,
                        &__location {
                            color: $color-text;
                        }
                    }
                }
            }

            &__container-img {
                position: relative;
                width: 100%;
                height: 350px;
                overflow: hidden;
                border-radius: $border-radius;
                background: url(/wp-content/themes/noyau/assets/images/icon-events.png) no-repeat center $color-gray;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__title {
                color: $color-text;
                margin: 30px 0 10px;
                line-height: 2rem;
                text-decoration: none;
                transition: $duration;
            }

            &__intro {
                color: $color-white;
            }

            &__details {
                display: flex;
            }

            &__date {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 15px;
                left: 15px;
                z-index: 2;
                padding: 0 5px;
                color: $color-text;

                svg {
                    width: 26px;
                    height: 26px;
                    fill: $color-dark;
                    position: relative;
                    margin: 0 0 25px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -40px;
                    right: -100px;
                    width: 320px;
                    height: 300px;
                    background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/forme-date.svg) no-repeat center;
                    z-index: 0;
                    transform: scale(1);
                    transition: $duration;
                }

                > p {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-content: center;
                    text-align: center;
                }

                &__day {
                    font-family: $font-family--heading;
                    font-size: 2rem;
                    font-weight: $font-weight-bold;
                    line-height: 1;
                }
                
                &__month {
                    font-size: $font-size--text-small;
                    text-transform: uppercase;
                }
            }

            &__hour,
            &__location {
                display: flex;
                align-items: center;
                margin-right: 15px;
                color: $color-text;

                svg {
                    margin-right: 5px;
                    fill: $color-second;
                    width: 24px;
                    height: 24px;

                    path {
                        fill: $color-second;
                    }
                }
            }
        }

        &__btn-all {
            position: relative;
            margin: 0;
            z-index: 2;
        }
    }

    //======================================================================================================
    // En direct
    //======================================================================================================
    .direct-kiosque {
        position: relative;
        background-color: $color-main;
        padding: 40px 0 105px 0;
        
        &:before {
            content: "";
            position: absolute;
            top: -55px;
            width: 0;
            height: 0;
            border-style: solid;
            z-index: 1;
            left: 0;
            border-width: 0 0 55px 100vw;
            border-color: transparent transparent $color-main transparent;
        }

        > .container {
            position: relative;
            display: flex;
            justify-content: space-between;

            &:before {
                content: "";
                position: absolute;
                left: calc(50% + 5px);
                bottom: -200px;
                transform: translateX(-50%);
                width: 400px;
                height: 400px;
                background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/cercles-kiosque.svg) no-repeat center;
                background-size: contain;
                z-index: 2;
            }
        }
    }

    .direct {
        position: relative;
        z-index: 3;
        width: 470px;
        margin-right: 130px;

        &__subtitle {
            color: $color-white;
        }

        &__title {
            color: $color-text;

            &:before {
                background-color: $color-white;
            }
        }

        &__buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-end;

            &__page {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-weight: $font-weight-bold;
                font-size: $font-size--4;
                line-height: 1.5rem;

                > span {
                    margin: 5px 0 15px;
                }

                &--sms {
                    width: 205px;
                    height: 210px;
                    background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-alertessms.svg) no-repeat center;
                    background-size: contain;

                    &:hover {
                        background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-alertessms-hover.svg) no-repeat center;
                        background-size: contain;
                    }
                }

                &--nl {
                    width: 220px;
                    height: 200px;
                    background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-newsletter.svg) no-repeat center;
                    background-size: contain;

                    &:hover {
                        background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-newsletter-hover.svg) no-repeat center;
                        background-size: contain;
                    }
                }

                &--fb {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    width: 460px;
                    height: 110px;
                    background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-facebook.svg) no-repeat center;
                    background-size: contain;
                    margin-top: 25px;

                    svg {
                        margin: 0 25px 0 20px;

                        rect {
                            transition: $duration;
                        }
                    }

                    > span {
                        margin: 0;
                    }

                    &:hover {
                        color: $color-text;
                        text-decoration: underline;

                        svg {
                            rect {
                                fill: $color-main;
                            }
                        }
                    }
                }
            }
        }
    }

    //======================================================================================================
    // Kiosque
    //======================================================================================================
    .documents {
        position: relative;
        z-index: 3;

        &__subtitle {
            color: $color-fourth;
        }

        &__title {
            color: $color-text;

            &:before {
                background-color: $color-fourth;
            }
        }

        .doc {
            display: flex;

            &__container-img {
                width: 270px;
                height: 380px;
                overflow: hidden;
                filter: drop-shadow(10px 0px 50px rgba(0, 0, 0, 0.08));
                background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) no-repeat center $color-gray;
                flex: none;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__title {
                color: $color-text;
                line-height: 2rem;
                margin: 0 0 25px;
            }

            &__content {
                padding: 0 0 0 30px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            &__details {
                &__extension {
                    text-transform: uppercase;
                }
            }
            &__buttons {
                display: flex;
                flex-wrap: wrap;
                margin-top: 35px;
            }

            &__btn {
                display: flex;
                align-items: center;
                font-weight: $font-weight-bold;
                font-size: $font-size--text-small;
                margin: 0 20px 20px 0;

                svg {
                    width: 30px;
                    height: 30px;
                    fill: $color-white;
                    background-color: $color-fourth;
                    border-radius: $border-radius--round;
                    margin-right: 10px;
                    transition: $duration;
                }

                span {
                    &:after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 1px;
                        left: 0;
                        background-color: $color-dark;
                        transition: $duration;
                    }
                }

                &:hover {
                    span {
                        &:after {
                            width: 100%;
                        }
                    }

                    svg {
                        background-color: $color-white;
                        fill: $color-dark;
                    }
                }
            }
        }

        &__btn-all {
            margin: 0;
            background-color: $color-fourth;
            border-color: $color-fourth;
            color: $color-white;

            svg {
                fill: $color-white;

                path {
                    fill: $color-white;
                    transition: $duration;
                }
            }

            &:hover {
                background-color: $color-white;
                border-color: $color-white;
                color: $color-text;

                svg {
                    fill: $color-text;

                    path {
                        fill: $color-text;
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home {
        &__header {
            margin-bottom: 40px;
        }

        .swiper-button {
            margin: 0 0 0 15px;
        }

        .access {
            &__item {
                width: 120px;
                height: 125px;
            }

            &__label {
                font-size: 0.75rem;
                margin: 0;
            }

            &__svg-container svg {
                margin-top: -15px;
            }
        }

        .alu {
            padding-bottom: 15px;

            &:before {
                right: -165px;
            }

            .slideshow {
                width: 640px;

                &__container  {
                    height: 600px;
                }

                &__content {
                    width: 350px;
                    height: 220px;
                    bottom: 0;
                }

                .shape-container  {
                    svg {
                        width: 640px;
                        height: 500px;
                    }
                }
                &__navigation {
                    bottom: 65px;
                }
            }
        }

        .posts {
            padding: 120px 0 110px;

            &:before {
                display: none;
            }

            &__item {
                width: 300px;

                &__container-img {
                    height: 195px;
                }
            }
        }

        .newsthread {
            margin: 0 0 -40px;
            transform: translateY(-50%);

            &__nav {
                right: 25px;
            }
        }

        .events {
            padding: 60px 0 120px;

            &__item {
                width: 460px;

                &__container-img {
                    height: 280px;
                }
            }
        }

        .direct-kiosque {
            padding: 95px 0 150px;

            > .container {
                &:before {
                    left: calc(50% + 20px);
                    bottom: -265px;
                }
            }
        }

        .direct {
            width: 405px;
            margin-right: 75px;

            &__buttons {
                &__page {
                    &--sms {
                        width: 185px;
                        height: 190px;
                    }

                    &--nl {
                        width: 200px;
                        height: 180px;
                    }

                    &--fb {
                        svg {
                            margin: 0 15px;
                        }
                    }
                }
            }
        }

        .documents {
            .doc {
                &__container-img {
                    width: 220px;
                    height: 315px;
                }

                &__content {
                    padding: 0 0 0 20px;
                }

                &__title {
                    margin: 0 0 15px;
                    font-size: 1.375rem;
                    line-height: 1.75rem;
                }

                &__btn {
                    margin: 0 10px 15px 0;

                    &:nth-child(even){
                        margin: 0 0 15px 0;
                    }
                }

                &__buttons {
                    margin-top: 20px;
                }
            }

            &__btn-all {
                width: 220px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .home {
        .access-alu {
            >.container {
                flex-direction: column-reverse;
            }
        }

        .alu {   
            height: 585px;

            .slideshow {    
                height: 585px;
                width: 620px;

                &--video {
                    .shape-container--video {
                        display: none;
                    }
    
                    .shape-container--img {
                        display: block;
                    }
                }

                .shape-container {
                    svg {
                        height: 485px;
                        width: 620px;
                    }
                }

                &__navigation {
                    bottom: 50px;
                }
            }
        }

        .access {
            position: relative;
            z-index: 3;
            padding: 45px 0 10px;

            &__list {
                justify-content: space-around;
            }

            &__item {
                width: 140px;
                height: 145px;
                margin: 0 10px 20px;
            }
        }

        .posts {
            padding: 120px 0 80px;

            > .container {
                &:before {
                    right: -320px;
                }
            }

            &__list {
                flex-wrap: wrap;
            }

            &__item {
                margin-bottom: 30px;
            }
        }

        .newsthread {
            &__nav {
                right: 20px;
            }

            &__title {
                padding-right: 20px;
                flex: auto;

                &:before {
                    left: -180px;
                }

                &__text {
                    font-size: 1.625rem;
                    line-height: 1.875rem;
                    text-align: center;
                }

                svg {
                    margin: 0 0 0 20px;
                }
            }

            &__link {
                padding-right: 20px;
                max-width: 255px;
            }
        }

        .events {
            > .container {
                &:after {
                    right: -310px;
                    bottom: -230px;
                }
            }

            &__item {
                width: 300px;

                &:before {
                    right: -80px;
                }

                &__container-img {
                    height: 180px;
                }

                &__title {
                    margin: 20px 0 10px;
                }

                &__details {
                    flex-direction: column;
                }

                &__date {
                    bottom: 10px;
                    left: 10px;

                    &:before {
                        top: -35px;
                        right: -50px;
                        width: 280px;
                        height: 260px;
                        background-size: contain;
                    }
                }
            }
        }

        .direct-kiosque {
            padding: 105px 0 190px;

            > .container {
                flex-direction: column;

                &:before {
                    left: -210px;
                    bottom: -265px;
                    transform: translateX(0);
                    right: auto;
                }
            }
        }

        .direct {
            width: 100%;
            margin-right: 0;
            margin-bottom: 60px;

            &__buttons {
                &__page {
                    &--fb {
                        flex-direction: column;
                        justify-content: center;
                        width: 185px;
                        height: 190px;
                        background: url(/wp-content/themes/missillac/assets/src/images/sprite-images/shapes/direct/forme-facebook-rwd.svg) no-repeat 50%;
                        margin-top: 0;

                        > span {
                            display: flex;
                            padding: 0 27px 5px;
                        }

                        svg {
                            margin: 0;
                        }
                    }
                }
            }
        }

        .documents {
            .doc {
                &__title {
                    margin: 0 0 30px;
                }

                &__content {
                    padding: 0 0 0 30px;
                    max-width: 300px;
                }

                &__btn {
                    margin: 0;

                    span {
                        display: none;
                    }

                    &:nth-child(2n){
                        margin: 0;
                    }
                }

                &__buttons {
                    margin-top: 30px;
                }
            }

            &__btn-all {
                width: 250px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home {
        &__header {
            justify-content: center;

            &__left {
                align-items: center;
            }
        }

        .swiper-button {
            margin: 0 7.5px;
        }

        .alu {   
            height: 440px;

            &:before {
                right: -185px;
            }

            .slideshow {    
                height: 440px;
                width: 320px;

                &__container {
                    height: 440px;
                    width: 320px;
                }

                .shape-container {
                    svg {
                        height: 250px;
                        width: 320px;
                    }
                }

                &__content {
                    bottom: auto;
                    top: 208px;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &__navigation {
                    bottom: auto;
                    top: 180px;
                    right: auto;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 3;
                }
            }
        }

        .access {
            padding: 35px 0 10px;

            &__list {
                justify-content: space-between;
            }

            &__item {
                width: 120px;
                height: 125px;
                margin: 0 10px 30px;
            }
        }

        .posts {
            padding: 120px 0 230px;

            &:after {
                top: -1px;
            }

            > .container {
                padding-bottom: 70px;

                &:before {
                    right: -380px;
                }
            }

            &__list {
                flex-direction: column;
            }

            &__btn-all {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        .newsthread {
            margin-bottom: -150px;

            &__container {
                display: block;
                height: auto;
                padding: 20px 25px 30px;
            }

            &__slider {
                text-align: center;
            }

            &__link {
                padding-right: 0;
                max-width: 100%;
            }

            &__title {
                padding: 0;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                margin-bottom: 40px;

                &:before {
                    left: -95px;
                    top: -125px;
                }

                svg {
                    margin: auto auto 10px;
                    width: 80px;
                    height: 50px;
                }
            }

            &__nav {
                width: 100%;
                justify-content: center;
                margin-top: 25px;
                position: relative;
                right: auto;
            }
        }

        .events {
            padding: 60px 0 110px;

            > .container {
                padding-bottom: 70px;

                &:after {
                    bottom: -150px;
                }
            }

            &__header {
                &__right {
                    position: absolute;
                    bottom: 0;
                }
            }

            &__list {
                flex-direction: column;
            }

            &__item {
                margin-bottom: 30px;
            }
        }

        .direct-kiosque {
            padding: 80px 0 150px;

            > .container {
                &:before {
                    display: none;
                }
            }
        }

        .direct {
            margin-bottom: 20px;

            &__buttons {
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &__page {
                    margin-bottom: 20px;
                }
            }
        }

        .documents {
            .doc {
                flex-direction: column;
                align-items: center;

                &__content {
                    padding: 0;
                    text-align: center;
                }

                &__title {
                    margin: 15px 0 30px;
                }

                &__info {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                }

                &__btn {
                    align-items: flex-start;
                    margin: 0 5px 15px;
                }

                &__buttons {
                    margin-top: 10px;
                }
            }

            &__btn-all {
                margin: auto;
            }
        }
    }
}
