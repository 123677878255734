//======================================================================================================
// Comarquage Archive
//======================================================================================================
.wrapper {

    #comarquage {

        // Main
        #co-page {
            float: none;
            margin-top: 45px;            

            a {
                color: $color-text;
                font-size: $font-size--text;
            }
            
            &.comarquage-home .co-home-theme {
                margin-bottom: 60px;
                
                h2 {
                    text-transform: inherit;
                    text-align: left;
                }

                .home-theme-list {
                    display: flex;
                    justify-content: flex-start;
                    margin-top: 25px;

                    .home-theme-list-item {
                        min-height: 375px;
                        margin: 15px;
                        background-color: $color-white;
                        border-radius: $border-radius;
                        box-shadow: $shadow;

                        &.co-col-2, &.co-col-1-4, &.co-col-1-5 {
                            width: 267px;
                        }

                        &.co-col-2 {     
                            &:first-child, &:nth-child(6), &:nth-child(11), &:nth-child(17) {
                                margin-left: 0;
                            }
        
                            &:nth-child(5), &:nth-child(10), &:nth-child(15) {
                                margin-right: 0;
                            }
                        }

                        &.co-col-1-4 {
                            &:first-child, &:nth-child(5), &:nth-child(10), &:nth-child(16) {
                                margin-left: 0;
                            }
        
                            &:nth-child(4), &:nth-child(9), &:nth-child(15) {
                                margin-right: 0;
                            }
                        }

                        &.co-col-1-5 {
                            &:first-child, &:nth-child(5), &:nth-child(10), &:nth-child(15) {
                                margin-left: 0;
                            }
        
                            &:nth-child(4), &:nth-child(9), &:nth-child(14) {
                                margin-right: 0;
                            }
                        }
                        

                        .home-item-icon {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: $card-content--padding 0;
                            background-color: $color-main;
                            border-radius: $border-radius;
                        }

                        h3 {
                            margin-top: 20px;
                            padding: 0 $card-content--padding;
                            text-align: left;
                            font-size: $font-size--text;
                            font-weight: $font-weight--heading;
                            line-height: $line-height--heading;

                            a {
                                font-size: $font-size--text;
                                font-weight: $font-weight--heading;
                                color: $color-text;

                                &:hover, &:focus {
                                    color: $color-text;
                                }
                            }
                        }

                        ul {
                            margin: 0;
                            padding: 0 $card-content--padding $card-content--padding $card-content--padding;
                            text-align: left;

                            li {
                                display: flex;
                                align-items: center;
                                box-sizing: inherit;
                                margin: 10px 0;
                                padding-left: 20px;
                                font-size: $font-size--text;
                            }
                        }
                    }
                }
            }

            .co-panel {
                margin: 0;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius;

                &.co-panel-gray {
                    background-color: $color-white;
                }

                .co-panel-heading {
                    margin: 0;
                    padding: 10px 15px;

                    h2 {
                        margin: 0;
                        padding: 0;
                        border-bottom: 0;
                        text-transform: none;
                        font-size: $font-size--3;
                    }
                } 

                .co-panel-body {
                    padding: 10px 0 0 0;

                    ul.list-top-dotted, ul.list-arrow {
                        list-style: none;
                        padding: 0;

                        li {
                            display: flex;
                            align-items: center;
                            margin: 10px 0;
                            padding: 0 20px;
                            border-color: $color-gray;

                            &::marker {
                                display: none;
                            }

                        }
                    }

                    ul.list-top-dotted li {
                        &:last-child {
                            border-bottom: 1px dashed $color-gray;
                        }
                    }

                    ul.list-arrow li {
                        margin: 0;
                        padding: 10px 0 10px 35px; 

                        &::before {
                            left: 15px;
                        }
                    }


                    p.link-all {
                        margin-top: 20px;
                        padding: 0;
                        border-top: 0;

                        a {
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            appearance: none;
                            -webkit-appearance: none;
                            cursor: pointer;
                            margin: 5px 14px 5px 0;
                            padding: $btn-padding;
                            background: $btn-bg;
                            border: $btn-border-width $btn-border-style $btn-border-color;
                            border-radius: $btn-border-radius;
                            font-family: $btn-font-family;
                            font-weight: $btn-font-weight;
                            font-size: $btn-font-size;
                            line-height: $btn-line-height;
                            color: $btn-color;
                            text-transform: $btn-text-transform;
                            text-decoration: none;
                            text-align: center;
                            transition: all $duration ease;

                            &:hover,
                            &:focus,
                            &:active {
                                color: $btn-color--hover !important;
                                background: $btn-bg--hover;
                                border-color: $btn-border-color--hover;
                            }
                        }
                    }
                }
            }
        }


        // Footer
        #co-footer {
            display: none !important;
        }



    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .wrapper {

        #comarquage {

            // Main
            #co-page {
                &.comarquage-home .co-home-theme {
                    .home-theme-list {
                        .home-theme-list-item {
                            &.co-col-2, &.co-col-1-4, &.co-col-1-5 {
                                width: 288px;
                            }

                            &.co-col-2 {     
                                &:nth-child(6), &:nth-child(11), &:nth-child(17) {
                                    margin-left: 15px;
                                }
                                &:nth-child(5), &:nth-child(10), &:nth-child(15) {
                                    margin-right: 15px;
                                }

                                &:nth-child(5), &:nth-child(10), &:nth-child(14) {
                                    margin-left: 0;
                                }
                                &:nth-child(3), &:nth-child(7), &:nth-child(11) {
                                    margin-right: 0;
                                }
                            }

                            &.co-col-1-4 {
                                &:nth-child(5), &:nth-child(10), &:nth-child(16) {
                                    margin-left: 15px;
                                }
                                &:nth-child(4), &:nth-child(9), &:nth-child(15) {
                                    margin-right: 15px;
                                }

                                &:nth-child(4), &:nth-child(8) {
                                    margin-left: 0;
                                }
                                &:nth-child(3), &:nth-child(7) {
                                    margin-right: 0;
                                }
                            }

                            &.co-col-1-5 {
                                &:nth-child(5), &:nth-child(10), &:nth-child(15) {
                                    margin-left: 15px;
                                }
                                &:nth-child(4), &:nth-child(9), &:nth-child(14) {
                                    margin-right: 15px;
                                }

                                &:nth-child(3), &:nth-child(7), &:nth-child(10) {
                                    margin-right: 0;
                                }

                                &:nth-child(4), &:nth-child(8), &:nth-child(11) {
                                    margin-left: 0;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }


}


// 960
@media screen and (max-width: $medium) {

    .wrapper {

        #comarquage {

            // Main
            #co-page {
                &.comarquage-home .co-home-theme {
                    .home-theme-list {
                        .home-theme-list-item {
                            &.co-col-2, &.co-col-1-4, &.co-col-1-5 {
                                width: 288px;
                            }

                            &.co-col-2 {     
                                &:nth-child(5), &:nth-child(10), &:nth-child(14) {
                                    margin-left: 15px;
                                }
                                &:nth-child(3), &:nth-child(7), &:nth-child(11) {
                                    margin-right: 15px;
                                }

                                &:nth-child(3), &:nth-child(6), &:nth-child(9), &:nth-child(11) {
                                    margin-left: 0;
                                }
                                &:nth-child(2), &:nth-child(5), &:nth-child(7) , &:nth-child(10) {
                                    margin-right: 0;
                                }
                            }

                            &.co-col-1-4 {
                                &:nth-child(4), &:nth-child(8) {
                                    margin-left: 15px;
                                }
                                &:nth-child(3), &:nth-child(7) {
                                    margin-right: 15px;
                                }

                                &:nth-child(3), &:nth-child(6) {
                                    margin-left: 0;
                                }
                                &:nth-child(2), &:nth-child(4), &:nth-child(7) {
                                    margin-right: 0;
                                }
                            }

                            &.co-col-1-5 {
                                &:nth-child(3), &:nth-child(7), &:nth-child(10) {
                                    margin-right: 15px;
                                }
                                &:nth-child(4), &:nth-child(8), &:nth-child(11) {
                                    margin-left: 15px;
                                }

                                &:nth-child(2), &:nth-child(4), &:nth-child(7), &:nth-child(9), &:nth-child(11) {
                                    margin-right: 0;
                                }
                                &:nth-child(3), &:nth-child(5), &:nth-child(8), &:nth-child(10), &:nth-child(12) {
                                    margin-left: 0;
                                }
                            }
                            
                        }
                    }
                }

                .co-panel  {
                    .co-panel-body {
                        .co-row {
                            margin-top: 0;

                            .co-col-1-2 {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

}


@media screen and (max-width: $small) {

    .wrapper {

        #comarquage {

            // Main
            #co-page {
                &.comarquage-home .co-home-theme {
                    .home-theme-list {
                        .home-theme-list-item {
                            &.co-col-2, &.co-col-1-4, &.co-col-1-5 {
                                width: 100%;
                            }

                            &.co-col-2 {     
                                margin: 15px 0 !important;
                            }

                            &.co-col-1-4 {
                                margin: 15px 0 !important;
                            }

                            &.co-col-1-5 {
                                margin: 15px 0 !important;
                            }
                            
                        }
                    }
                }

                .co-panel  {
                    .co-panel-heading {
                        padding: 0;
                    }
                    .co-panel-body {
                        ul.list-arrow {
                            li {
                                margin: 0;
                                padding: 10px 0 10px 25px;

                                &:before {
                                    left: 5px;
                                }
                            }  
                        }

                        p.link-all a {
                            margin-right: 0;
                        }
                    }
                }

            }
        }
    }

}
