#nav-main {
    position: relative;
    z-index: 5;
    width: 100%;
    height: 100%;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;
        height: 100%;
        padding-top: 10px;
    } 

    .nav-main-item {
        
        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &:last-child {
           .nav-main-link {
            padding-right: 0;
            margin-right: 0;

                &:after {
                    display: none;
                }
            }

            &:hover {
                .nav-main-link {
                    &:before {
                        width: 100%;
                    }
                }
            }
        }

        &:hover {
            .nav-main-link {
                padding-top: 20px;

                &:before {
                    width: calc(100% - 46px);
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            font-family: $font-family--heading;
            color: $color-text;
            text-transform: uppercase;
            font-weight: $font-weight-extrabold;
            font-size: 1.25rem;
            line-height: 1.4rem;
            padding-right: 46px;
            margin-right: 46px;
            padding-top: 0;
            
            > span {
                font-weight: $font-weight-light;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
            }

            &:before {
                bottom: -5px;
                left: 0;
                width: 0;
                height: 10px;
                background: $color-third;
                border-radius: 10px;
                z-index: 15;
            }

            &:after {
                top: 50%;
                right: 0;
                width: 2px;
                height: 44px;
                background: $color-light;
                transform: translateY(-50%);
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 0 40px;
            background: $color-main;
            z-index: 12;

            &:before,
            &:after {
                content: '';
                position: absolute;
                height: 100%;
                width: 2000px;
                top: 0;
                background: $color-main;
            }

            &:before {
                left: -2000px;
            }

            &:after {
                right: -2000px;
            }

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                position: relative;
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                &::before {
                    content: "";
                    display: inherit;
                    position: absolute;
                    left: 0;
                    width: 0;
                    height: 100%;
                    background: $color-white;
                    transition: all .35s;
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    padding: 10px 30px 10px 0;
                    color: $color-text;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 1px;
                        background: $color-white;
                        transition: all .35s;
                    }

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 0;
                        color: $color-white;
                        transition: all $duration;
                    }
                }

                &:hover {
                    
                    &::before {
                        width: 100%;
                    }

                    a {
                        color: $color-text;
                        padding: 10px 30px 10px 15px;

                        &::after {
                            color: $color-text;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 15px 10px 15px 30px;
                    border-bottom: 0;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::after {
                        right: auto;
                        left: 10px;
                        color: $color-text;
                        font-size: 1rem;
                    }
                } 

                &:hover {
                    a {
                        padding: 15px 10px 15px 30px;
                    }
                }
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none;
    align-items: center;
    cursor: pointer;
    padding: 0;
    color: $color-text;
    font-family: $font-family--heading;
    font-weight: $font-weight-extrabold;
    font-size: 1.25rem;
    height: 40px;

    .navicon {
        position: relative;
        display: block;
        height: 2px;
        top: 0;
        width: 20px;
        margin-right: 5px;
        background: $color-dark;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-dark;
            transition: all $duration ease-out;
        }

        &:before {
            top: 6px;
        }

        &:after {
            top: -6px;
        } 
    }
}

.header--fixed {
    #nav-main {
        padding-left: 200px;

        .menu__nav__list {
            padding-top: 0;
        }

        .nav-main-item {
            .nav-main-link {
                font-size: 1.125rem;
                padding-right: 25px;
                margin-right: 25px;
            }

            &:hover {
                .nav-main-link {
                    &:before {
                        width: calc(100% - 25px);
                    }
                }
            }

            &:last-child {
                .nav-main-link {
                    padding-right: 0;
                }

                &:hover {
                    .nav-main-link {
                        &:before {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    #nav-main {
        .nav-main-item {
            &:last-child {
                .nav-main-link {
                    padding-right: 0;
                    margin-right: 0;
                }
            }

            .nav-main-link {
                padding-right: 25px;
                margin-right: 25px;
            }

            &:hover {
                .nav-main-link {
                    &:before {
                        width: calc(100% - 25px);
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: initial;
        display: flex;
        align-items: flex-end;

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            width: 100%;
            height: auto;
            left: 0;
            right: 0;
            margin: auto;
            top: 110px;
            background: $color-main;
            padding: 0;
            box-shadow: $shadow;

            &:before,
            &:after {
                content: '';
                position: absolute;
                height: 100%;
                width: 1000px;
                background-color: $color-main;
            }

            &:before {
                left: -1000px;
            }

            &:after {
                right: -1000px;
            }
        }

        .nav-main-item {

            &:hover {
                .nav-main-link {
                    padding-top: 0;
                }
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-white;
                color: $color-text;

                &:before,
                &:after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {

                .nav-main-link {
                    background-color: $color-white;
                    color: $color-text;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 0;

                    &:before,
                    &:after {
                        display: none;
                    }

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-child-item {
                        a {
                            padding: 10px 30px 10px 10px;
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            margin: 0;
            padding: 0 20px;
            text-transform: uppercase; 
        }
    }
    
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        order: 1;
        width: auto;

        .nav-main_icon {
            padding: 0;
        }
    }
}
